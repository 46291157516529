@import 'styles/_variables';
@import 'styles/_mixins';

.modal-confirm-screen {
    .confirm-modal {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 33px;
            margin: 52px 0 14px;
        }

        &__message {
            width: 211px;
            font-size: 1rem;
            line-height: 28px;
            letter-spacing: 0.5px;
            color: $color-gray-blue;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
            max-height: 600px;
            overflow-y: auto;

            &--wide {
                width: 340px;
            }

            &--superwide {
                width: 540px;
            }

            &--multiline {
                white-space: pre-line;
            }
        }

        &__submit-button {
            @include submit-btn;

            width: 210px;
            color: $color-white;
            margin-top: 10px;

            @include hover-effect;

            &--green {
                background: $color-green;
            }

            &--red {
                background: $color-red;
            }

            &--blue {
                background: $bg-color-button;
            }

            &--white {
                background: $color-white;
                border: 1px solid $border-color;
                color: $color-gray-blue;
            }

            &--wide {
                width: 220px;
            }

            &--superwide {
                width: 220px;
            }
        }
    }
}
