@import 'styles/_variables';
@import 'styles/_mixins';

.header-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    min-width: 850px;
    height: 70px;
    // Attention: if we change the height, we need to update orderbook loader margin top as well!

    &__icons {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 168px;
        margin-left: 80px;
        cursor: pointer;
        margin-bottom: 4px;
    }

    &__hamburger-button {
        display: none;
        margin-left: 16px;
        cursor: pointer;
    }

    &__nav {
        display: flex;
        align-items: center;
        margin-right: 80px;
    }

    &__link {
        margin: 0 13px;
        color: $color-gray-blue;
        font-weight: 600;

        &--active {
            color: $color-dark-blue;
        }

        &--button {
            background-color: $bg-color-button;
            color: $color-white;
            padding: 10px 15px;
            border-radius: 4px;
        }

        &--cancel-button {
            position: relative;
            display: inline-block;
            margin-left: 0;
        }

        &--order-button {
            position: relative;
            display: inline-block;
            margin-left: 0;
            margin-right: 0;
        }

        &:hover {
            color: $color-hover;
        }
    }

    &__cancel-button {
        background-color: $color-red;
        color: $color-white;
        padding: 10px 15px;
        border-radius: 4px;

        &:hover {
            color: $color-hover;
        }
    }

    &__actions-wrapper {
        display: flex;
    }

    &__action {
        margin: 0 10px;
        cursor: pointer;
        display: flex;

        @include hover-effect;
    }

    &__profile-details {
        position: relative;
    }

    &__profile-details-link {
        display: flex;
        align-items: center;
        margin-left: 16px;

        @include hover-effect;
    }

    &__profile-name {
        color: $color-gray-blue;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-right: 6px;
    }

    &__arrow-circle {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 10px;
        right: -1px;
    }

    &__notif-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__notif-link {
        display: flex;
        margin: 0 10px;

        @include hover-effect;
    }

    &__notif-counter {
        color: $color-white;
        font-weight: 700;
        font-size: 0.875rem;
        background-color: $color-brand-blue;
        text-align: center;
        border-radius: 11px;
        width: 22px;
        height: 22px;
        position: absolute;
        top: -2px;
        right: -4px;
    }

    &__single-logo {
        width: 86px;
        align-self: center;
        margin-bottom: 15px;
        display: none;
    }

    &__close-sidebar {
        width: 12px;
        align-self: flex-end;
        margin-right: 21px;
        display: none;
        margin-bottom: 23px;

        @include hover-effect;
    }

    @include screen-vlg {
        font-size: 0.8rem;

        &__link {
            margin: 0 5px;

            &--button {
                padding: 10px 5px;
                font-size: 0.75rem;
            }
        }

        &__cancel-button {
            padding: 10px 5px;
            font-size: 0.75rem;
        }

        &__profile-details-link {
            margin-left: 8px;
        }
    }

    @include screen-lg {
        &__profile-details-link {
            margin-left: 8px;
        }

        &__logo {
            margin-left: 46px;
        }

        &__links-wrapper {
            position: absolute;
            display: flex;
            flex-direction: column;
            left: -1000px;
            padding: 23px 3px;
            top: 0;
            bottom: 0;
            background-color: $bg-color-body;
            z-index: 1;
            transition: all 300ms ease;

            &--active {
                left: 0;
                z-index: 1600;
            }
        }

        &__link {
            height: 37px;
            font-size: 0.875rem;
            font-weight: 700;
            padding-left: 10px;
            min-width: 186px;
            border-bottom: 1px solid $border-color;
            display: flex;
            align-items: center;

            &--button {
                padding-left: 12px;
                margin-left: 0;
                margin-right: 0;
                justify-content: center;
            }

            &--cancel-button {
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding-left: 0;
                margin-left: 4px;
                border-bottom: none;
            }

            &--order-button {
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding-left: 0;
                margin-left: 4px;
                border-bottom: none;
            }
        }

        &__cancel-button {
            width: 100%;
            font-size: 0.875rem;
            font-weight: 700;
            display: flex;
            align-items: center;
            border-bottom: none;
            padding-left: 12px;
            height: 37px;
            justify-content: center;
        }

        &__hamburger-button {
            display: inline;
        }

        &__single-logo {
            width: 40px;
            display: block;
            cursor: pointer;
        }

        &__close-sidebar {
            display: block;
        }
    }
}

.lock-modal {
    .modal-confirm-screen {
        z-index: 100;
    }
}
